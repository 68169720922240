

export const config ={
    name:'头彩' ,
    p:'tc',
    platform : 'tc',
    baseUrl : "https://api1.rsg-preprod.xyz",
    // baseUrl: 'http://api1.jinxiangru.com',
    download:'https://toucai.458347.top/',
}

// export const config ={
//     p:'ttc2',
//     platform : 'ttc2',
//     name:'人人娱乐' ,
//     baseUrl : "https://api1.dcyslg.com",
//     // baseUrl: 'http://api1.jinxiangru.com',
//     download:'https://d.rrcai.vip/',
// }

// export const config ={
//     p:'hc',
//     platform : 'hc',
//     name:'环彩' ,
//     baseUrl : "https://api1.rrcvip.net",
//     // baseUrl: 'http://api1.jinxiangru.com',
//     download:'https://d.rrcai.vip/',
// }

const UER_INFO_KEY = 'UER_INFO_KEY_' + config.platform.toUpperCase();
const TOKEN_KEY = config.platform.toUpperCase() + '_TOKEN_KEY';
const BASE_URL = config.platform.toUpperCase() + '_BASE_URL';
const ReConfirmation_ = config.platform.toUpperCase() + '_ReConfirmation_';
const Confirmation_ = config.platform.toUpperCase() + '_Confirmation_';
const IssuseNumber_ =config.platform.toUpperCase() +  '_IssuseNumber_';
const ALLCONFIG_KEY =config.platform.toUpperCase() +  '_ALLCONFIG_KEY';
const Home_LotteryList = config.platform.toUpperCase() +'_Home_LotteryList';
const AgentId = config.platform.toUpperCase() +'_AgentId';
const RechargeUrl = config.platform.toUpperCase() +'_RechargeUrl';
const BuyTogetherType = config.platform.toUpperCase() +'_BuyTogetherType';
const PERSONALCENTER_KEY = 'PERSONALCENTER_KEY';
const PERSONALCENTER_OPENKEYS = 'PERSONALCENTER_OPENKEYS';
const NAV_INDEX = 'NAV_INDEX';
const Article = config.platform.toUpperCase() + '_Article';


export const setUserInfo = value => {
    localStorage.setItem(UER_INFO_KEY, JSON.stringify(value));
};

export const getUserInfo = () => {
    return JSON.parse(localStorage.getItem(UER_INFO_KEY));
};

export const removeUserInfo = () => {
    return localStorage.removeItem(UER_INFO_KEY);
};

export const setToken = value => {
    localStorage.setItem(TOKEN_KEY, JSON.stringify(value));
};

export const getToken = () => {
    return JSON.parse(localStorage.getItem(TOKEN_KEY));
};

export const removeToken = () => {
    return localStorage.removeItem(TOKEN_KEY);
};

export const setConfirmationS = (gameCode, value) => {
    sessionStorage.setItem(Confirmation_ + gameCode, JSON.stringify(value));
};
export const getConfirmationS = (gameCode) => {
    return JSON.parse(sessionStorage.getItem(Confirmation_ + gameCode));
};
export const removeConfirmationS = (gameCode) => {
    return sessionStorage.removeItem(Confirmation_ + gameCode);
};

export const setPersonalTabIndex = value => {
    sessionStorage.setItem(PERSONALCENTER_KEY, JSON.stringify(value));
};

export const getPersonalTabIndex = () => {
    let value = JSON.parse(sessionStorage.getItem(PERSONALCENTER_KEY));
    if (!value) {
        value = '0';
        setPersonalTabIndex(value);
    }
    return value;
};

export const setPersonalOpenkeys = value => {
    sessionStorage.setItem(PERSONALCENTER_OPENKEYS, JSON.stringify(value));
};

export const getPersonalOpenkeys = () => {
    return JSON.parse(sessionStorage.getItem(PERSONALCENTER_OPENKEYS));
};

export const setAgentId = value => {
    sessionStorage.setItem(AgentId, JSON.stringify(value));
};

export const getAgentId = () => {
    return JSON.parse(sessionStorage.getItem(AgentId));
};

export const setNavIndex = value => {
    sessionStorage.setItem(NAV_INDEX, JSON.stringify(value));
};

export const getNavIndex = () => {
    return JSON.parse(sessionStorage.getItem(NAV_INDEX));
};
export const setBuyTogetherTypeIndex = value => {
    sessionStorage.setItem(BuyTogetherType, JSON.stringify(value));
};

export const getBuyTogerTypeIndex = () => {
    return JSON.parse(sessionStorage.getItem(BuyTogetherType));
};

export const removeNavIndex = () => {
    return sessionStorage.removeItem(NAV_INDEX);
};

export const setHomeLotteryList = (value) => {
    sessionStorage.setItem(Home_LotteryList, JSON.stringify(value));
};

export const getHomeLotteryList = () => {
    return JSON.parse(sessionStorage.getItem(Home_LotteryList));
};

export const setArticle = (value) => {
    sessionStorage.setItem(Article, JSON.stringify(value));
};

export const getArticle = () => {
    return JSON.parse(sessionStorage.getItem(Article));
};

export const removeArticle = () => {
    return sessionStorage.removeItem(Article);
};

export const setAllConfigInfo = value => {
    sessionStorage.setItem(ALLCONFIG_KEY, JSON.stringify(value));
};

export const getAllConfigInfo = () => {
    return JSON.parse(sessionStorage.getItem(ALLCONFIG_KEY));
};

export const setRechargeurl = value => {
    sessionStorage.setItem(RechargeUrl, JSON.stringify(value));
};

export const getRechargeurl = () => {
    return JSON.parse(sessionStorage.getItem(RechargeUrl));
};

export const setBaseUrl = value => {
    sessionStorage.setItem(BASE_URL, JSON.stringify(value));
};

export const getBaseUrl = () => {
    return JSON.parse(sessionStorage.getItem(BASE_URL));
};