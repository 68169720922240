import React from 'react' 
import useReactRouter from 'use-react-router'
import { Redirect } from 'react-router-dom'
import { getUserInfo } from '../utils/base'
const AuthLogin = ( Layout , props ) =>{
    if(!getUserInfo()){
        return <Redirect to="/" />
    } else {
      return <Layout {...props} />
    }
}

export default AuthLogin 