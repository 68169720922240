import React, { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import AuthLogin from './components/AuthLogin'
import { config } from './utils/base'

const Home = lazyWithPreload(() => import('./pages/Home1'))
const HomeRRC = lazyWithPreload(() => import('./pages/HomeRRC'))
const HomeHC = lazyWithPreload(() => import('./pages/HomeHC'))
const Demo = lazyWithPreload(() => import('./pages/Test'))
const Qipai = lazyWithPreload(() => import('./pages/Qipai'))
const BuyTogether = lazyWithPreload(() => import('./pages/BuyTogether'))
const SSC = lazyWithPreload(() => import('./pages/lottery/SSC/SSC'))
const CTC = lazyWithPreload(() => import('./pages/lottery/CTC/CTC'))
const SSQ = lazyWithPreload(() => import('./pages/lottery/SSQ/SSQ'))
const JSK3 = lazyWithPreload(() => import('./pages/lottery/JSK3/JSK3'))
const JCZQ = lazyWithPreload(() => import('./pages/lottery/JCZQ'))
const CTZQ = lazyWithPreload(() => import('./pages/lottery/CTZQ'))
const BJDC = lazyWithPreload(() => import('./pages/lottery/BJDC'))
const QWZQ = lazyWithPreload(() => import('./pages/lottery/QWZQ'))
const OZB = lazyWithPreload(() => import('./pages/lottery/OZB'))
const NewsCenter = lazyWithPreload(() => import('./pages/NewsCenter'))
const PersonalCenter = lazyWithPreload(() => import('./pages/PersonalCenter'))
const FollowOrder = lazyWithPreload(() => import('./pages/FollowOrder'))
const Record = lazyWithPreload(() => import('./pages/Record'))
const ActivityCenter = lazyWithPreload(() => import('./pages/ActivityCenter'))
const LotteryCenter = lazyWithPreload(() => import('./pages/LotteryCenter'))
const LotteryDetail = lazyWithPreload(() => import('./pages/LotteryDetail'))
const CtLotteryDetail = lazyWithPreload(() => import('./pages/CtLotteryDetail'))
const GpLotteryDetail = lazyWithPreload(() => import('./pages/GpLotteryDetail'))
const BuyTogetherDetail = lazyWithPreload(() => import('./pages/BuyTogetherDetail'))
const BillingDetail = lazyWithPreload(() => import('./pages/BillingDetail'))
const Basketball = lazyWithPreload(() => import('./pages/lottery/JCLQ'))
const Register = lazyWithPreload(() => import('./pages/Register'))
const JDBGame = lazyWithPreload(() => import('./pages/JDBGame'))
const PersonalPage = lazyWithPreload(() => import('./pages/PersonalPage'))
const HelpCenter = lazyWithPreload(() => import('./pages/HelpCenter'))
const HelpDetail = lazyWithPreload(() => import('./pages/HelpDetail'))
const QipaiHall = lazyWithPreload(() => import('./pages/QipaiHall'))
const TiyuHall = lazyWithPreload(() => import('./pages/TiyuHall'))
const AGHall = lazyWithPreload(() => import('./pages/AGHall'))
const BoLe = lazyWithPreload(() => import('./pages/BoLe'))
const QQ = lazyWithPreload(() => import('./pages/QQ'))
const Login = lazyWithPreload(() => import('./pages/Login'))
const Chart = lazyWithPreload(() => import('./pages/Chart'))
const RechargeConfigure = lazyWithPreload(() => import('./pages/RechargeConfigure'))

export const lottRoute = (gamecode) => {
    switch (gamecode.toLowerCase()) {
        case 'rrcwfc':
            return '/ssc/rrcwfc';
        case 'rrcssc':
            return '/ssc/rrcssc';
        case 'rrcffc':
            return '/ssc/rrcffc';
        case 'cqssc':
            return '/ssc/cqssc';
        case 'xjssc':
            return '/ssc/xjssc';
        case 'tcjlffc':
        case 'jlffc':
            return '/ssc/tcjlffc';
        case 'tcjlssc':
            return '/ssc/tcjlssc';
        case 'txffc':
            return '/ssc/txffc';
        case 'jlwfc':
            return '/ssc/jlwfc';
        case 'btcffc':
            return '/ssc/btcffc';
        case 'hnffc':
            return '/ssc/hnffc';
        case 'jx11x5':
            return '/ssc/jx11x5';
        case 'sd11x5':
            return '/ssc/sd11x5';
        case 'gd11x5':
            return '/ssc/gd11x5';
        case 'hub11x5':
            return '/ssc/hub11x5';
        case 'xyft':
            return '/ssc/xyft';
        case 'xyft2':
            return '/ssc/xyft2';
        case 'fhcqc':
            return '/ssc/fhcqc';
        case 'fhxjc':
            return '/ssc/fhxjc';
        case 'bjpk10':
            return '/ssc/bjpk10';
        case 'bjpk10c':
            return '/ssc/bjpk10c';
        case '360ffc':
            return '/ssc/360ffc';
        case 'tx2fc':
            return '/ssc/tx2fc';
        case 'hljssc':
            return '/ssc/hljssc';
        case 'cq20c':
            return '/ssc/cq20c';
        case 'hlj20c':
            return '/ssc/hlj20c';
        case 'hnwfc':
            return '/ssc/hnwfc';
        case 'jczq':
            return '/jczq';
        case 'jclq':
            return '/jclq';
        case 'bjdc':
            return '/bjdc';
        case 'qwzq':
            return '/qwzq';
        case 'pl3':
            return '/ctc/pl3';
        case 'pl5':
            return '/ctc/pl5';
        case 'fc3d':
            return '/ctc/fc3d';
        case 'xqxc':
            return '/ctc/xqxc';
        case 'qxc':
            return '/ctc/qxc';
        case 'qlc':
            return '/ctc/qlc';
        case 'ssq':
            return '/ssq/ssq';
        case 'dlt':
            return '/ssq/dlt';
        case 'jsk3':
            return '/jsk3/jsk3';
        case 'ahk3':
            return '/jsk3/ahk3';
        case 'ctzq':
            return '/ctzq';
        case 'ozb':
            return '/ozb';
        default:
            return false;
    }
}

function lazyWithPreload(f) {
    const Component = lazy(f);
    Component.preload = f;
    return Component
}

function Router() {
    return (
        <div className={config.platform}>
            <Suspense fallback={null}>
                <Switch>
                    {/* <Route path='/' exact component={ props => <HomeHC {...props} /> } /> */}
                    <Route path='/' exact render={(props) => {
                        return config.platform === 'tc' ? <Home {...props} /> :config.platform === 'hc'  ? <HomeHC {...props} /> : <HomeRRC {...props} />
                    }}/>
                    <Route path='/demo' component={props => <Demo {...props} />} />
                    {/* <Route path='/homehc' component={props => <HomeHC {...props} />} /> */}
                    <Route path='/buyTogether' component={props => <BuyTogether {...props} />} />
                    <Route path='/qipai' component={props => <Qipai {...props} />} />
                    <Route path='/ssc/:gameCode' component={props => <SSC {...props} />} />
                    <Route path='/ctc/:gameCode' component={props => <CTC {...props} />} />
                    <Route path='/ssq/:gameCode' component={props => <SSQ {...props} />} />
                    <Route path='/jsk3/:gameCode' component={props => <JSK3 {...props} />} />
                    <Route path='/jczq' component={props => <JCZQ {...props} />} />
                    <Route path='/ctzq' component={props => <CTZQ {...props} />} />
                    <Route path='/bjdc' component={props => <BJDC {...props} />} />
                    <Route path='/qwzq' component={props => <QWZQ {...props} />} />
                    <Route path='/ozb' component={props => <OZB {...props} />} />
                    <Route path='/newsCenter' component={props => <NewsCenter {...props} />} />
                    <Route path='/personalCenter' component={props => AuthLogin(PersonalCenter, props)} />
                    <Route path='/followOrder' component={props => <FollowOrder {...props} />} />
                    <Route path='/record/:mid' component={props => <Record {...props} />} />
                    <Route path='/activityCenter' component={props => <ActivityCenter {...props} />} />
                    <Route path='/lotteryCenter' component={props => <LotteryCenter {...props} />} />
                    <Route path='/ctlotteryDetail/:gameCode/:issuseNumber' component={props => <CtLotteryDetail {...props} />} />
                    <Route path='/GplotteryDetail/:gameCode/:issuseNumber' component={props => <GpLotteryDetail {...props} />} />
                    <Route path='/lotteryDetail/:gameCode/:issuseNumber' component={props => <LotteryDetail {...props} />} />
                    <Route path='/buyTogetherDetail/:gameCode/:orderId' component={props => <BuyTogetherDetail {...props} />} />
                    <Route path='/billingDetail/:orderId' component={props => <BillingDetail {...props} />} />
                    <Route path='/jclq' component={props => <Basketball {...props} />} />
                    <Route path='/register/:type' component={props => <Register {...props} />} />
                    <Route path='/JDBGame' component={props => <JDBGame {...props} />} />
                    <Route path='/personalPage/:mid/:type' component={props => <PersonalPage {...props} />} />
                    <Route path='/helpCenter' component={props => <HelpCenter {...props} />} />
                    <Route path='/helpDetail/:type/:name' component={props => <HelpDetail {...props} />} />
                    <Route path='/qipaiHall' component={props => <QipaiHall {...props} />} />
                    <Route path='/tiyuHall' component={props => <TiyuHall {...props} />} />
                    <Route path='/AGHall' component={props => <AGHall {...props} />} />
                    <Route path='/boLe' component={props => <BoLe {...props} />} />
                    <Route path='/qq' component={props => <QQ {...props} />} />
                    <Route path='/login' component={props => <Login {...props} />} />
                    <Route path='/chart' component={props => <Chart {...props} />} />
                    <Route path='/rechargeConfigure' component={props => <RechargeConfigure {...props} />} />
                </Switch>
            </Suspense>
        </div>
    )
}

export default Router